import React, { memo, useState } from "react";
import classNames from "classnames";

import useModal from "@lc/lib/hooks/useModal";

import routes from "@lc/lib/routes";
import { useProvider } from "@lc/lib/Provider";

import SearchIcon from "@lc/ui/icons/Search";
import MenuIcon from "@lc/ui/icons/Menu";
import UserCircleIcon from "@lc/ui/icons/UserCircle";

import Avatar from "@lc/ui/components/Avatar";
import Badge from "@lc/ui/components/Badge";
import Button from "@lc/ui/components/Button";
import Link from "@lc/ui/components/Link";
import Text from "@lc/ui/components/Text";
import Image from "@lc/ui/components/Image";

import SearchForm from "@lc/ui/template/SearchForm";
import ExpertiseList from "@lc/ui/template/home/components/ExpertiseList";

import styles from "./menu.module.scss";
import Menu from "../Menu";

const MobileMenu = (): React.ReactElement => {
  const {
    badges: { hasNotification, hasMessages },
    user: { isLogged, userData },
  } = useProvider();
  const hasAvatar = !!userData?.user?.avatar;

  const { openModal, Modal } = useModal({
    content: () => (
      <div className={styles.modalcontent}>
        <div>
          <Text styleAs="h5" color="green">
            <b>Encontre o consultório ideal para você</b>
          </Text>
          <SearchForm qaLabel="modal-banner-search-form" />
        </div>
        <div className={styles.expertiseList}>
          <ExpertiseList modal />
        </div>
      </div>
    ),
  });
  const [open, setOpen] = useState(false);

  return (
    <>
      {Modal}
      <nav className={styles.nav}>
        <div className={styles.logo}>
          <Link href="/">
            <Image
              priority
              src="images/logoOnly.png"
              alt="Logo letclinic"
              objectFit="contain"
              width={35}
              height={50}
            />
          </Link>
        </div>
        <div>
          <Button noPadding naked onClick={(): void => openModal()}>
            <Text styleAs="body3" color="secondary" noMargin>
              <span className={classNames(styles.item, styles.active)}>
                <SearchIcon />
              </span>
              Buscar
            </Text>
          </Button>
        </div>
        <div>
          {isLogged ? (
            <Link
              textProps={{ styleAs: "body3", color: "secondary" }}
              href={routes.internal.account}
              naked
            >
              <>
                <Avatar size="28px" style={{ margin: "auto" }}>
                  {hasAvatar ? (
                    <Image
                      src={userData?.user?.avatar}
                      width={36}
                      height={36}
                      alt="Imagem do perfil do usuário"
                    />
                  ) : (
                    <Text element="span" noMargin color="white">
                      <b>
                        {userData?.user?.first_name.charAt(0)}
                      </b>
                    </Text>
                  )}
                </Avatar>
                Perfil
              </>
            </Link>
          ) : (
            <Link
              textProps={{ styleAs: "body3", color: "secondary" }}
              href={routes.public.login}
              naked
            >
              <span className={styles.item}>
                <UserCircleIcon />
                Entrar
              </span>
            </Link>
          )}
        </div>
        <div>
          <Badge
            show={hasNotification || hasMessages}
            position={{ top: "-4px", left: "7px" }}
          >
            <Button noPadding naked onClick={(): void => setOpen(true)}>
              <Text styleAs="body3" color="secondary">
                <span className={styles.item}>
                  <MenuIcon />
                  Menu
                </span>
              </Text>
            </Button>
          </Badge>
        </div>
      </nav>

      <Menu
        hasNotification={hasNotification}
        hasMessages={hasMessages}
        open={open}
        onClose={(): void => setOpen(false)}
      />
    </>
  );
};

export default memo(MobileMenu);
