import React, { memo, useEffect, useRef, useState } from "react";

import { WhatsappIcon2 } from "@lc/ui/icons/socialIcons";
import UserCircleIcon from "@lc/ui/icons/UserCircle";
import SearchOutlineIcon from "@lc/ui/icons/SearchOutline";

import {
  RiCalendar2Line,
  RiNotification4Line,
  RiQuestionLine,
  RiComputerLine,
} from "react-icons/ri";
import { FiMessageSquare } from "react-icons/fi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { MdLogout } from "react-icons/md";

import classNames from "classnames";

import routes from "@lc/lib/routes";
import useClickOutside from "@lc/lib/hooks/useClickOutside";
import useLockBodyScroll from "@lc/lib/hooks/useScrollLock";
import { useProvider } from "@lc/lib/Provider";
import useDrawer from "@lc/lib/hooks/useDrawer";

import Avatar from "@lc/ui/components/Avatar";
import Badge from "@lc/ui/components/Badge";
import Button from "@lc/ui/components/Button";
import Link from "@lc/ui/components/Link";
import Text from "@lc/ui/components/Text";
import Divisor from "@lc/ui/components/Divisor";
import Image from "@lc/ui/components/Image";

import Helper from "@lc/ui/template/Helper";

import styles from "./menu.module.scss";

type Props = {
  open: boolean;
  hasNotification?: boolean;
  hasMessages?: boolean;
  onClose?: () => void;
  anchorRef?: React.RefObject<HTMLElement>;
  closeOutSideRef?: React.RefObject<HTMLElement>;
  desktop?: boolean;
};

const Menu = ({
  open,
  onClose = (): void => {},
  anchorRef,
  closeOutSideRef,
  desktop = false,
  hasNotification = false,
  hasMessages = false,
}: Props): React.ReactElement | null => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuAnchored, setMenuAnchored] =
    useState<{ left: number | undefined }>();

  const {
    user: { isLogged, userData },
  } = useProvider();
  const hasAvatar = !!userData?.user?.avatar;
  const { lockBodyScroll, unlockBodyScroll } = useLockBodyScroll();
  const { openHelper, Drawer } = useDrawer({
    direction: "RTL",
    content: <Helper />,
  });
  useClickOutside(closeOutSideRef ?? menuRef, onClose);

  useEffect(() => {
    if (!desktop && open) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
    return (): void => unlockBodyScroll();
  }, [lockBodyScroll, desktop, open, unlockBodyScroll]);

  useEffect(() => {
    if (desktop && open) {
      const { right = 0 } = anchorRef?.current?.getBoundingClientRect() || {};
      const { width = 0 } = menuRef?.current?.getBoundingClientRect() || {};

      setMenuAnchored({ left: right - width });
    }
  }, [anchorRef, desktop, open]);

  const menuItems = [
    {
      id: "welcome",
      show: isLogged && !desktop,
      Element: () => (
        <div className={styles.welcomeUser}>
          <Text noOverflow noMargin element="span" color="green">
            <b>Olá, {userData?.user?.first_name}</b>
          </Text>
          <Avatar>
            <Image
              src={userData?.user?.avatar}
              width={36}
              height={36}
              alt={userData?.user?.first_name.charAt(0)}
            />
          </Avatar>
        </div>
      ),
    },
    {
      id: "login",
      mobile: false,
      show: !isLogged,
      Element: () => (
        <Link naked href={routes.public.login} className={styles.bold}>
          Entrar
        </Link>
      ),
    },
    {
      id: "signup",
      show: !isLogged,
      Element: () => (
        <Link qaLabel="Cadastrar-se" naked href={routes.public.login}>
          Cadastrar-se
        </Link>
      ),
    },
    {
      id: "my ads",
      show: isLogged,
      Element: () => (
        <Link naked href={routes.internal.place.list}>
          <span className={styles.itemWithicon}>
            <RiComputerLine />
            <b>Meus anúncios</b>
          </span>
        </Link>
      ),
    },
    {
      id: "my booking",
      show: isLogged,
      Element: () => (
        <Link naked href={routes.internal.myBooking(userData?.id)}>
          <span className={styles.itemWithicon}>
            <RiCalendar2Line />
            <b>Minhas reservas</b>
          </span>
        </Link>
      ),
    },
    {
      id: "divisor1",
      show: true,
      Element: () => <Divisor />,
    },
    {
      id: "want to ad",
      show: true,
      Element: () => (
        <Link
          naked
          href={
            isLogged ? routes.internal.place.register : routes.public.hosterHome
          }
        >
          <span className={styles.itemWithicon}>
            <HiOutlineSpeakerphone />
            Quero anunciar
          </span>
        </Link>
      ),
    },
    {
      id: "want to rent",
      show: true,
      Element: () => (
        <Link naked href={routes.public.search}>
          <span className={styles.itemWithicon}>
            <SearchOutlineIcon />
            Quero alugar
          </span>
        </Link>
      ),
    },
    {
      id: "divisor2",
      show: true,
      Element: () => <Divisor />,
    },
    {
      id: "notification",
      show: isLogged,
      Element: () => (
        <Link naked href={routes.internal.notification}>
          <Badge
            show={hasNotification}
            position={{ top: "6px", right: "-11px" }}
          >
            <span className={styles.itemWithicon}>
              <RiNotification4Line />
              Notificações
            </span>
          </Badge>
        </Link>
      ),
    },
    {
      id: "messages",
      show: isLogged,
      Element: () => (
        <Link naked href={routes.internal.chat}>
          <Badge show={hasMessages} position={{ top: "6px", right: "-11px" }}>
            <span className={styles.itemWithicon}>
              <FiMessageSquare />
              Mensagens
            </span>
          </Badge>
        </Link>
      ),
    },
    {
      id: "divisor3",
      show: isLogged,
      Element: () => <Divisor />,
    },
    {
      id: "myaccount",
      show: isLogged,
      Element: () => (
        <Link naked href={routes.internal.account}>
          <span className={styles.itemWithicon}>
            <UserCircleIcon />
            Minha conta
          </span>
        </Link>
      ),
    },
    {
      id: "divisor4",
      show: isLogged,
      Element: () => <Divisor />,
    },
    {
      id: "talk to us",
      show: true,
      Element: () => (
        <Link newWindow naked href={routes.public.whatsapp}>
          <span className={styles.itemWithicon}>
            <WhatsappIcon2 fontSize={14} />
            Fale com a gente
          </span>
        </Link>
      ),
    },
    {
      id: "help",
      show: true,
      Element: () => (
        <Button
          fullWidth
          noPadding
          naked
          asLink
          justifyContent="flex-start"
          onClick={() => {
            openHelper();
            onClose();
          }}
        >
          <Text element="span" noMargin>
            <span className={styles.itemWithicon}>
              <RiQuestionLine />
              Ajuda
            </span>
          </Text>
        </Button>
      ),
    },
    {
      id: "logout",
      show: isLogged,
      Element: () => (
        <Link naked href={routes.internal.logout}>
          <span className={styles.itemWithicon}>
            <MdLogout />
            Sair
          </span>
        </Link>
      ),
    },
  ];

  return (
    <>
      {Drawer}
      <div
        className={classNames({ [styles.backdrop]: !desktop && open })}
      ></div>
      <div
        ref={menuRef}
        style={menuAnchored}
        className={classNames(styles.menu, {
          [styles.open]: open,
          [styles.close]: !open,
          [styles.menuMobile]: !desktop,
          [styles.menuDesktop]: desktop,
        })}
      >
        {!isLogged && !desktop ? (
          <div className={styles.logoTop}>
            <Image
              src="images/logo.png"
              alt="Logo letclinic"
              objectFit="contain"
              width={160}
              height={60}
            />
          </div>
        ) : null}
        <ul className={classNames(styles.menuList, { [styles.open]: open })}>
          {menuItems
            .filter((item) => item.show)
            .map(({ Element, ...item }) => (
              <li
                key={item.id}
                className={classNames(styles.menuItem, {
                  [styles.menuListDesktop]: !isLogged || desktop,
                })}
                onClick={onClose}
              >
                <Element />
              </li>
            ))}
        </ul>
        {isLogged && !desktop ? (
          <div className={styles.logoBottom}>
            <Image
              src="images/logo.png"
              alt="Logo letclinic"
              objectFit="contain"
              width={130}
              height={50}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default memo(Menu);
